import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import { formatNumber, patronExistsMessage } from './utilities';

ReactModal.setAppElement('#root');

export default function Footer() {
  const [modalOpen, setModalOpen] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [joinMessage, setJoinMessage] = useState('');
  const [joinFinePrint, setJoinFinePrint] = useState('');
  const [patronName, setPatronName] = useState('');
  const [submitResponse, setSubmitResponse] = useState('');

  useEffect(() => {
    fetch('/.netlify/functions/api?table=join').then(response => {
      response.json().then(json => {
        const data = json[0];
        setJoinMessage(data.message);
        setJoinFinePrint(data.fineprint);
      });
    });
  }, []);

  const handleJoinFooter = () => {
    if (mobileNumber.length !== 12) {
      return false;
    }
    setModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const handleJoin = () => {
    if (mobileNumber.length !== 12 || patronName.length < 2) {
      return false;
    }
    fetch('/.netlify/functions/api?table=patrons').then(response => {
      response.json().then(json => {
        let exists = false;
        json.forEach(item => {
          const {name, number} = item;
          if (name === patronName && number === mobileNumber.replace(/-/g, '')) {
            exists = true;
          }
        });
        if (exists) {
          setSubmitResponse(patronExistsMessage);
        } else {
          fetch('/.netlify/functions/api?table=patrons', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              name: patronName,
              number: mobileNumber
            })
          }).then(response => {
            console.log('response', response);
            const { status } = response;
            if (status === 403) {
              setSubmitResponse(patronExistsMessage);
            } else if (status === 200) {
              setSubmitResponse('Congratulations! You\'ve successfully been added as a Lulu Patron!');
            } else {
              setSubmitResponse('Oops, something went wrong! Give us a call or let us know in-person and we\'ll get to the bottom of it!');
            }
          });
        }
      });
    });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    document.body.style.overflow = 'unset';
  };

  const handleMobileNumberChange = event => {
    event.preventDefault();
    let { value } = event.target;
    if (value.length > 12) {
      return false;
    }
    setMobileNumber(formatNumber(value));
  };

  const handlePatronNameChange = event => {
    event.preventDefault();
    let { value } = event.target;
    setPatronName(value);
  };

  const checkForSubmission = event => {
    const { currentTarget, keyCode } = event;
    if (keyCode !== 13) {
      return;
    }
    const inputName = currentTarget.getAttribute('name');
    if (inputName === 'join-number-footer') {
      handleJoinFooter();
    }
    if (inputName === 'join-number-modal') {
      handleJoin();
    }
  };

  const generateForm = () => {
    if (submitResponse) {
      return (
        <div className="flex flex-col items-center my-4 text-xl sm:text-4xl mediterranean-cuisine text-center p-1 sm:p-8">{submitResponse}</div>
      );
    }

    return (
      <>
        <div className="flex flex-col items-center mt-8 mb-16 text-black">
          <div className="w-3/4 lulu-kabab text-md sm:text-3xl">
            {joinMessage}
          </div>
        </div>
        <div className="flex flex-col items-center my-4">
          <input name="join-name-modal" type="text" placeholder="Your Name" value={patronName} onChange={handlePatronNameChange} className="input input-bordered bg-black" />
        </div>
        <div className="flex flex-col items-center my-4">
          <input name="join-number-modal" type="text" placeholder="(XXX)XXX-XXXX" value={mobileNumber} onKeyUp={checkForSubmission} onChange={handleMobileNumberChange} className="input input-bordered bg-black join-item" />
        </div>
        <div className="flex flex-col items-center my-4">
          <button onClick={handleJoin} className="btn btn-lg bg-black hover:bg-neutral-800">Join Now!</button>
        </div>
        <div className="absolute bottom-10 pr-14 text-xs">
          {joinFinePrint}
        </div>
      </>
    );
  };

  return (
    <>
      <ReactModal isOpen={modalOpen} onRequestClose={handleCloseModal} style={{ overlay: { zIndex: 1000 } }}>
        <button type="button" onClick={handleCloseModal} className="absolute top-0 right-0 bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500">
          <span className="sr-only">Close Modal</span>
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="p-1 sm:p-8 h-full">
          {generateForm()}
        </div>
      </ReactModal>
      <footer className="footer p-10 pb-20 sm:p-20 bg-neutral-950 text-white mt-20">
        <nav className="min-w-40">
          Monday - Saturday<br />
          Lunch: 11:00AM - 2:00PM<br />
          Dinner: 5:00PM - 8:30PM
        </nav>
        <nav className="min-w-40">
          <a href="tel:6017907666">📞 (601) 790-7666</a>
          <a href="mailto:lulukabab23@gmail.com">✉️ lulukabab23@gmail.com</a>
          <a href="https://www.google.com/maps/dir//Lulu+Kabab,+574+US-51,+Ridgeland,+MS+39157" rel="noreferrer" target="_blank">🗺️ 574 Highway 51<br />
            Ridgeland, MS 39157</a>
        </nav>
        <nav className="min-w-40">
          <div className="grid grid-flow-col gap-4">
            <a href="https://www.facebook.com/p/Lulu-Kabab-Mediterranean-Cuisine-100078095529354/" rel="noreferrer" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" /></svg></a>
            <a href="https://www.instagram.com/lulukabab" rel="noreferrer" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" /></svg></a>
          </div>
        </nav>
        <nav className="min-w-40">
          <div className="slogan text-xl pl-4">Become a Patron</div>
          <div className="join">
            <input name="join-number-footer" type="text" placeholder="(XXX)XXX-XXXX" value={mobileNumber} onKeyUp={checkForSubmission} onChange={handleMobileNumberChange} className="input input-bordered input-sm md:input-md bg-black join-item" />
            <button onClick={handleJoinFooter} className="btn btn-neutral btn-sm md:btn-md bg-black hover:bg-neutral-800 join-item outline-none">Join</button>
          </div>
        </nav>
      </footer>
    </>
  );
}

import { useState, useEffect } from 'react';

import { formLabel, inputField } from './utilities';

function Content() {
  const [formData, setFormData] = useState({
    home: '',
    menu: [],
    about: [],
    join: {},
    errors: {},
    loading: false
  });

  useEffect(() => {
    fetch('/.netlify/functions/api?table=home').then(response => {
      response.json().then(json => {
        setFormData((prevState) => ({ ...prevState, home: json[0] }));
      });
    });
    fetch('/.netlify/functions/api?table=menu').then(response => {
      response.json().then(json => {
        const items = {};
        json.forEach(({ course, ...rest }) => {
          if (!items[course]) {
            items[course] = [];
          }
          items[course].push(rest);
        });
        setFormData((prevState) => ({ ...prevState, menu: items }));
      });
    });
    fetch('/.netlify/functions/api?table=about').then(response => {
      response.json().then(json => {
        setFormData((prevState) => ({ ...prevState, about: json }));
      });
    });
    fetch('/.netlify/functions/api?table=join').then(response => {
      response.json().then(json => {
        setFormData((prevState) => ({ ...prevState, join: { message: json[0], fineprint: json[0] } }));
      });
    });
  }, []);

  const handleHomeChange = event => {
    const { value } = event.target;
    const home = { ...formData.home, content: value };
    setFormData((prevState) => ({ ...prevState, home }));
  };

  const handleAboutChange = event => {
    const { name, value } = event.target;
    const index = name.match(/\d+/)[0];
    let { about } = formData;
    about[index].content = value;
    setFormData((prevState) => ({ ...prevState, about }));
  };

  const handleJoinChange = event => {
    const { name, value } = event.target;
    const item = name === 'message' ? { ...formData.join.message, message: value } : { ...formData.join.fineprint, fineprint: value }
    const join = { ...formData.join, [name]: item };
    setFormData((prevState) => ({ ...prevState, join }));
  };

  const handleCourseChange = event => {
    let { name, value } = event.target;
    const split = name.split('-');
    const course = event.target.getAttribute('data-course');
    const id = split[1];
    const item = split[0];
    const currentIndex = formData.menu[course].findIndex(item => item.id === id);
    const newMenu = { ...formData.menu };
    if (['true', 'false'].indexOf(value) > -1) {
      value = value === 'true' ? false : true;
    }
    newMenu[course][currentIndex][item] = value;
    setFormData((prevState) => ({ ...prevState, menu: newMenu }));
  };

  const renderCourses = () => {
    return Object.keys(formData.menu).map(key => {
      if (formData.menu[key]) {
        return <label className={formLabel}>{key}</label> && renderCourse(key);
      }
      return null;
    })
  };

  const getItemById = id => {
    let result = {};
    Object.keys(formData.menu).forEach(key => {
      for (const item of formData.menu[key]) {
        if (item.id === id) {
          result = item;
          break;
        }
      }
    });
    return result;
  };

  const saveMenuItem = event => {
    event.preventDefault();
    const { name } = event.target;
    const id = name.slice(5);
    const item = getItemById(id);
    fetch(`/.netlify/functions/api?table=menu&id=${id}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ item })
    }).then(response => {
      response.json().then(json => {
        console.log('Update Response', json);
      });
    });
  };

  const saveTextField = event => {
    event.preventDefault();
    const { name, value } = event.target;
    const dataId = event.target.getAttribute('data-id');
    const isJoinIndex = ['message', 'fineprint'].indexOf(name);
    const isJoin = isJoinIndex > -1;
    const table = isJoin ? 'join' : name.split('[')[0];
    fetch(`/.netlify/functions/api?table=${table}&id=${dataId}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ item: isJoin ? `${name}-${value}` : value })
    }).then(response => {
      response.json().then(json => {
        console.log('Update Response', json);
      });
    });
  };

  const handlePriceChange = event => {
    const { ctrlKey, key } = event;
    if (ctrlKey || key.length > 1 || /[0-9.]/.test(key)) {
      return;
    }
    event.preventDefault();
  };

  const handlePriceLength = event => {
    event.preventDefault();
    let { value } = event.target;
    value = value.replace(/[^0-9.]/g, '');
    let parts = value.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts.slice(1).join('');
    }
    if (parts[1]) {
      value = parts[0] + '.' + parts[1].slice(0, 2);
    }
    event.target.value = value;
  };

  const renderCourse = course => {
    return formData.menu[course].map((item, index) => (
      <div className="border-b-2 border-black mb-5" key={`key-${index}`}>
        <input className={inputField} type="text" name={`product-${item.id}`} value={item.product} data-course={course} onChange={handleCourseChange} />
        <textarea className={`${inputField} no-resize h-24 resize-none`} name={`description-${item.id}`} value={item.description} data-course={course} onChange={handleCourseChange}></textarea>
        <input className={inputField} type="text" name={`price-${item.id}`} value={item.price} data-course={course} onKeyDown={handlePriceChange} onKeyUp={handlePriceLength} onChange={handleCourseChange} />
        <div className="flex">
          <input disabled className={`w-48 ${inputField}`} type="text" id="label" value="Is Vegetarian" />
          <div className="w-48">
            <input className="toggle relative top-3 left-5" type="checkbox" name={`isvegetarian-${item.id}`} data-course={course} value={item.isvegetarian} checked={item.isvegetarian} onChange={handleCourseChange} />
          </div>
        </div>
        <button onClick={saveMenuItem} name={`menu-${item.id}`} className="btn btn-sm w-full mb-3">Save</button>
      </div>
    ));
  };

  return (
    <>
      <div className="main-container container mx-auto mt-8 p-1 sm:p-2">
        <form className="w-full mx-auto">
          <div className="join join-vertical w-full">
            <div className="collapse collapse-arrow join-item border border-base-300">
              <input type="radio" name="my-accordion-4" defaultChecked />
              <div className="collapse-title text-xl font-medium">Home</div>
              <div className="collapse-content">
                <div className="w-full">
                  <label className={formLabel} htmlFor="home">Content</label>
                  <textarea className={`${inputField} no-resize h-48 resize-none`} id="home" value={formData.home.content} name="home" onChange={handleHomeChange}></textarea>
                  <button onClick={saveTextField} name="home" value={formData.home.content} data-id={formData.home.id} className="btn btn-sm w-full mb-3">Save</button>
                </div>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item border border-base-300">
              <input type="radio" name="my-accordion-4" />
              <div className="collapse-title text-xl font-medium">About</div>
              <div className="collapse-content">
                <div className="w-full">
                  <label className={formLabel}>Content</label>
                  {formData.about.map((paragraph, index) => {
                    return (<div key={`about[${index}]`}>
                      <textarea className={`${inputField} no-resize h-48 resize-none`} value={paragraph.content} name={`about[${index}]`} onChange={handleAboutChange}></textarea>
                      <button onClick={saveTextField} name={`about[${index}]`} value={paragraph.content} data-id={paragraph.id} className="btn btn-sm w-full mb-3">Save</button>
                    </div>);
                  })}
                </div>
              </div>
            </div>
            <div className="collapse collapse-arrow join-item border border-base-300">
              <input type="radio" name="my-accordion-4" />
              <div className="collapse-title text-xl font-medium">Join</div>
              <div className="collapse-content">
                {(!formData.join.message) ? null :
                  <div className="w-full">
                    <label className={formLabel} htmlFor="message">Message</label>
                    <textarea className={`${inputField} no-resize h-48 resize-none`} id="message" value={formData.join.message.message} name="message" onChange={handleJoinChange}></textarea>
                    <button onClick={saveTextField} name="message" value={formData.join.message.message} data-id={formData.join.message.id} className="btn btn-sm w-full mb-3">Save</button>
                  </div>
                }
                {(!formData.join.fineprint) ? null :
                  <div className="w-full">
                    <label className={formLabel} htmlFor="fineprint">Fineprint</label>
                    <textarea className={`${inputField} no-resize h-48 resize-none`} id="fineprint" value={formData.join.fineprint.fineprint} name="fineprint" onChange={handleJoinChange}></textarea>
                    <button onClick={saveTextField} name="fineprint" value={formData.join.fineprint.fineprint} data-id={formData.join.fineprint.id} className="btn btn-sm w-full mb-3">Save</button>
                  </div>
                }
              </div>
            </div>
            <div className="collapse collapse-arrow join-item border border-base-300">
              <input type="radio" name="my-accordion-4" />
              <div className="collapse-title text-xl font-medium">Menu</div>
              <div className="collapse-content">
                <div className="w-full">
                  {renderCourses()}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Content;

import { useState } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Outlet,
  Navigate
} from 'react-router-dom';
import netlifyIdentity from 'netlify-identity-widget';

import Login from './login';
import Admin from './admin';
import AdminHeader from './adminHeader';
import Sms from './sms';
import Content from './content';

import Doordash from './doordash';
import Header from './header';
import Home from './home';
import About from './about';
import Menu from './menu';
import Contact from './contact';
import Footer from './footer';

function App() {
  const [user, setUser] = useState(netlifyIdentity.currentUser());

  const PrivateRoute = () => {
    return user ? <Outlet /> : <Navigate to='/' />;
  };

  const login = callback => {
    netlifyIdentity.open();
    netlifyIdentity.on('login', userResponse => {
      netlifyIdentity.close();
      setUser(userResponse);
      callback();
    });
  };

  const logout = callback => {
    netlifyIdentity.logout();
    netlifyIdentity.on('logout', () => {
      setUser(null);
      callback();
    });
  };

  const SiteLayout = () => (
    <>
        <Doordash />
        {<Header />}
        <Outlet />
        <Footer />
    </>
  );

  const AdminLayout = () => (
    <>
      <AdminHeader user={user} logout={logout} />
      <Outlet />
    </>
  );

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route>
        <Route path='/' element={<SiteLayout />}>
            <Route index element={<Home />} />
            <Route path='menu' element={<Menu />} />
            <Route path='about' element={<About />} />
            <Route path='contact' element={<Contact />} />
            <Route path='login' element={<Login login={login} />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path='/admin' element={<AdminLayout />}>
              <Route index element={<Admin />} />
              <Route path='sms' element={<Sms />} />
              <Route path='content' element={<Content />} />
            </Route>
          </Route>
        </Route>
      </>
    )
  );

  return (
    <RouterProvider router={router} />
  );

}

export default App;

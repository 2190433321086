import { useState, useEffect } from 'react';
import { loadingMessage } from './utilities';

export default function About() {
  const [isLoading, setIsLoading] = useState(true);
  const [paragraphs, setParagraphs] = useState([]);
  const [fadeIn, setFadeIn] = useState('');

  useEffect(() => {
    fetch('/.netlify/functions/api?table=about').then(response => {
      response.json().then(json => {
        setParagraphs(json);
        setIsLoading(false);
        setTimeout(() => {
          setFadeIn('is-visible');
        }, 350);
      });
    });
  }, [setIsLoading]);

  const generateParagraphs = () => {
    return paragraphs.map((paragraph, index) => <p className="mb-4" key={`key-${index}`}>{paragraph.content}</p>);
  };

  if (isLoading) {
    return (
      <div className="main-container container mx-auto mt-8 p-1 sm:p-2 w-screen flex justify-center items-center text-5xl text-black">
        {loadingMessage}
      </div>
    );
  }

  return (
    <div className={`main-container container mx-auto mt-8 p-1 sm:p-2 fade-in ${fadeIn}`}>
      <div className="columns-1 sm:columns-2 gap-8">
        <div className="mb-4">
          <img src="images/about.jpg" className="rounded-lg" alt="Our Story" />
        </div>
        <div className="mb-4 text-black">
          {generateParagraphs()}
        </div>
      </div>
    </div>
  );
};

import { useState } from 'react';

import { currentPage } from './utilities';

export default function Header() {
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);

  const toggleMobileNav = () => {
    setMobileNavIsOpen(!mobileNavIsOpen);
  };

  const mobileNavClassList = () => mobileNavIsOpen ? 'open' : 'hidden';

  const isActive = page => {
    return currentPage() === page ? 'active current:!bg-blue-500' : '';
  };

  const navLinkStyle = 'current:!bg-white current:!text-black active:!bg-black active:!text-white rounded-md';

  const generateLinks = (isMobile) => {
    if (isMobile) {
      return (
        <>
          <li className="justify-center">
            <a href="/" className={`${navLinkStyle} inline text-center ${isActive('')}`}>Home</a>
          </li>
          <li className="justify-center">
            <a href="/menu" className={`${navLinkStyle} inline text-center ${isActive('menu')}`}>Menu</a>
          </li>
          <li className="justify-center">
            <a href="/about" className={`${navLinkStyle} inline text-center ${isActive('about')}`}>About</a>
          </li>
          <li className="justify-center">
            <a href="/contact" className={`${navLinkStyle} inline text-center ${isActive('contact')}`}>Contact</a>
          </li>
        </>
      );
    }
    return (
      <>
        <li>
          <a href="/" className={`${navLinkStyle} mr-4 ${isActive('')}`}>Home</a>
        </li>
        <li>
          <a href="/menu" className={`${navLinkStyle} mr-4 ${isActive('menu')}`}>Menu</a>
        </li>
        <li>
          <a href="/about" className={`${navLinkStyle} mr-4 ${isActive('about')}`}>About</a>
        </li>
        <li>
          <a href="/contact" className={`${navLinkStyle} ${isActive('contact')}`}>Contact</a>
        </li>
      </>
    );
  };

  return (
    <>
      <div id="desktop-nav" className="hidden sm:flex relative z-20 min-h-14">
        <div className="bg-neutral-950 text-white flex justify-center w-full fixed top-0 left-0">
          <ul className="menu sm:menu-horizontal pt-3 pb-2">
            {generateLinks(false)}
          </ul>
        </div>
      </div>
      <div id="mobile-nav" className="sm:hidden bg-neutral-950 relative z-20 min-h-14">
        <div className="fixed bg-neutral-950 w-full">
          <button onClick={toggleMobileNav} type="button" className="inline-flex items-center p-4 ms-3 text-sm bg-neutral-950 text-white sm:hidden hover:text-neutral-300">
            <span className="sr-only">Open sidebar</span>
            <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
            </svg>
          </button>
          <div id="mobile-list" className={mobileNavClassList()}>
            <div className="bg-neutral-950 text-white w-full flex justify-center">
              <ul className="menu flex">
                {generateLinks(true)}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { useState, useEffect } from 'react';

import Course from './course';

import { firstLetter, letters, blackBar, loadingMessage } from './utilities';

import vegetarian from './vegetarian.png';
import kabab from './kabab.png';

export default function Menu() {
  const [isLoading, setIsLoading] = useState(true);
  const [fadeIn, setFadeIn] = useState('');
  const [menu, setMenu] = useState({});

  useEffect(() => {
    fetch('/.netlify/functions/api?table=menu').then(response => {
      response.json().then(json => {
        const items = {};
        json.forEach(({ course, ...rest }) => {
          if (!items[course]) {
            items[course] = [];
          }
          items[course].push(rest);
        });
        setMenu(items);
        setIsLoading(false);
        setTimeout(() => {
          setFadeIn('is-visible');
        }, 350);
      });
    });
  }, [setIsLoading]);

  if (isLoading) {
    return (
      <div className="main-container container mx-auto mt-8 p-1 sm:p-2 w-screen flex justify-center items-center text-5xl text-black">
        {loadingMessage}
      </div>
    );
  }

  return (
    <div className={`main-container container mx-auto mt-8 p-1 sm:p-2 fade-in ${fadeIn}`}>
      <div>
        <div className="mb-8">
          <img className="mx-auto my-4 max-w-xl w-full" alt="Kabab" src={kabab} />
          <div className="mx-auto text-center mt-4 text-black lulu-kabab">
            <span className={firstLetter}>L</span><span className={`${letters} mr-2 sm:mr-4 md:mr-8`}>ULU</span><span className={firstLetter}>K</span><span className={letters}>ABAB</span>
          </div>
          <div className="mx-auto text-center mb-4 text-black mediterranean-cuisine text-2xl sm:text-4xl md:text-5xl lg:text-6xl">MEDITERRANEAN CUISINE</div>
          <div className={blackBar}></div>
          <div className="mx-auto text-center mb-4 text-black text-2xl md:text-5xl lg:text-6xl slogan">Made Fresh, Healthy, Daily</div>
          <div className={blackBar}></div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2">
          <Course heading='Appetizers' items={menu['Appetizers']} />
          <Course heading='Soups & Salads' subHeading='All soups & salads are served with pita bread' items={menu['Soups & Salads']} />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2">
          <div>
            <Course heading='Sandwiches' items={menu['Sandwiches']} />
            <Course heading='Kababs' subHeading='Grilled to your liking. All dishes served with feta salad, rice, hummus & pita bread' items={menu['Kababs']} />
            <Course heading='Kids' items={menu['Kids']} />
            <Course heading='Desserts' items={menu['Desserts']} />
          </div>
          <div>
            <Course heading='Lulu Specialties' subHeading='Any meal that comes with a salad can upgrade to a large salad for ' items={menu['Lulu Specialties']} />
            <Course heading='Sides' items={menu['Sides']} />
            <Course heading='Beverages' items={menu['Beverages']} />
          </div>
        </div>
      </div>
      <div className="relative text-right"><img className="inline-block is-vegetarian !left-0" width="26" height="26" alt="Vegetarian Option" src={vegetarian} />Vegetarian Option</div>
    </div>
  );
}

import { useState, useEffect } from 'react';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { loadingMessage } from './utilities';

const firstLetter = 'text-xl sm:text-3xl md:text-4xl lg:text-5xl';
const letters = 'text-xl sm:text-2xl md:text-3xl lg:text-4xl';
const blackBar = 'bg-black w-full h-1 md:h-2 mb-2';

const carouselImages = [
  {image: 'images/hummus.jpg', text: 'Appetizer: Hummus'},
  {image: 'images/lulu-kabab.jpg', text: 'Kabab: Lulu (Beef)'},
  {image: 'images/byob.jpg', text: 'BYOB! We\'ll supply the glasses!'},
  {image: 'images/halloum-cheese.jpg', text: 'Appetizer: Fried Halloum Cheese'},
  {image: 'images/grape-leaves.jpg', text: 'Appetizer: Grape Leaves'},
  {image: 'images/eggplant-moussaka.jpg', text: 'Lulu Specialty: Eggplant Moussaka'},
  {image: 'images/chicken-pita.jpg', text: 'Sandwich: Chicken Shawarma Pita with Hummus'},
  {image: 'images/soup.jpg', text: 'Appetizer: Lulu Chicken Soup'},
  {image: 'images/catering.jpg', text: 'We cater! Contact us for details.'},
  {image: 'images/shrimp-scampi.jpg', text: 'Lulu Specialty: Shrimp Scampi'},
  {image: 'images/tiramisu.jpg', text: 'Dessert: Tiramisu'},
  {image: 'images/turkish-coffee.jpg', text: 'Beverage: Turkish Coffee'},
  {image: 'images/shrimp-kabab.jpg', text: 'Kabab: Shrimp Kabab'},
  {image: 'images/greek-salad.jpg', text: 'Salad: Greek Salad'},
  {image: 'images/falafel.jpg', text: 'Appetizer: Falafel'},
  {image: 'images/baklava.jpg', text: 'Dessert: Baklava'}
];

export default function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [fadeIn, setFadeIn] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch('/.netlify/functions/api?table=home').then(response => {
      response.json().then(json => {
        setIsLoading(false);
        setContent(json[0].content);
        setTimeout(() => {
          setFadeIn('is-visible');
        }, 350);
      });
    });
  }, [setIsLoading]);

  if (isLoading) {
    return (
      <div className="main-container container mx-auto mt-8 p-1 sm:p-2 w-screen flex justify-center items-center text-5xl text-black">
        {loadingMessage}
      </div>
    );
  }

  const generateCarouselItems = () => {
    return carouselImages.map((carouselImage, index) => {
      return (
        <div key={`key-${index}`} className="w-full">
          <img src={carouselImage.image} alt="Placeholder" className="w-full rounded-lg" />
          <p className="legend">{carouselImage.text}</p>
        </div>
      );
    });
  };

  return (
    <div className={`main-container container mx-auto mt-8 p-1 sm:p-2 fade-in ${fadeIn}`}>
      <div className="columns-1 sm:columns-2 gap-8">
        <Carousel
          className="mb-4 sm:mb-40"
          animationHandler="fade"
          showArrows={true}
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          interval={6000}
          transitionTime={750}
        >
          {generateCarouselItems()}
        </Carousel>
        <div className="mb-4">
          <div className="mx-auto text-center mt-4 text-black lulu-kabab">
            <span className={firstLetter}>L</span><span className={`${letters} mr-2 sm:mr-4 md:mr-8`}>ULU</span><span className={firstLetter}>K</span><span className={letters}>ABAB</span>
          </div>
          <div className="mx-auto text-center mb-4 text-black mediterranean-cuisine text-lg sm:text-xl md:text-2xl lg:text-3xl">MEDITERRANEAN CUISINE</div>
          <div className={blackBar}></div>
          <div className="mx-auto text-center mb-4 text-black text-xl md:text-2xl lg:text-3xl slogan">Made Fresh, Healthy, Daily</div>
          <div className={blackBar}></div>
        </div>
        <div className="mt-8 mx-2 md:mx-5 lg:mx-8 text-gray-700 lulu-kabab text-md md:text-xl lg:text-2xl" dangerouslySetInnerHTML={{ __html: content }}></div>
        <div className="w-full text-center mt-8 underline">
          [ <a href="/menu" className="">See Our Menu</a> ]
        </div>
      </div>
    </div>
  );
};

import vegetarian from './vegetarian.png';

export default function Course({ heading, subHeading = '', items = [] }) {
  const generateHeading = () => {
    const words = heading.split(' ');
    return words.map((word, index) => {
      return word === '&' ? <span key={`heading-${index}`}>{word} </span> : <span key={`heading-${index}`}><span className="text-xl sm:text-3xl md:text-4xl lg:text-6xl">{word.charAt(0)}</span>{word.slice(1)} </span>;
    });
  };
  const forSoupsAndSalads = () => {
    return (
      <div className="px-4">
        <div className="text-black text-lg md:text-2xl -indent-6 font-semibold">For Any Soups or Salads</div>
        <div className="text-neutral-600">Add chicken shwarma <span className="text-black font-semibold">$5.99</span></div>
        <div className="text-neutral-600">Add gyro <span className="text-black font-semibold">$6.99</span></div>
        <div className="text-neutral-600">Add chicken shwarma & gyro <span className="text-black font-semibold">$7.99</span></div>
        <div className="text-neutral-600">Add shrimp (grilled) <span className="text-black font-semibold">$8.99</span></div>
      </div>
    );
  };

  const forKababs = () => {
    return (
      <div className="pt-2">Add extra meat <span className="text-black font-semibold">$10.99</span></div>
    );
  };

  const forSpecials = () => {
    return (
      <span className="text-black font-semibold">$5.99</span>
    );
  };
  return (
    <div className="mb-8 mr-0 sm:mr-8">
      <div className="course-heading bg-black text-white pl-4 py-1 text-lg sm:text-2xl md:text-3xl lg:text-5xl uppercase">
        {generateHeading()}
      </div>
      <div className="pl-8 py-2 text-md">
        { subHeading }
        { heading.indexOf('Soups') > -1 ? forSoupsAndSalads() : '' }
        { heading.indexOf('Kababs') > -1 ? forKababs() : '' }
        { heading.indexOf('Specialties') > -1 ? forSpecials() : '' }
      </div>
        { items.map((item, index) => {
          const { isvegetarian, product, description, price } = item;
          return (
            <div className="px-4" key={`item-${index}`}>
              { isvegetarian ? <img className="inline-block is-vegetarian" width="26" height="26" alt="Vegetarian Option" src={vegetarian} /> : '' }
              <span className='text-black text-lg md:text-2xl pl-4 font-semibold'>{product}</span> <span className="text-neutral-600">{description}</span> <span className="text-black font-semibold">${price}</span>
            </div>
          );
        }) }
    </div>
  );
}
